import axios, {
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";
import type { GarageVehicle, PaymentMethod } from "@/types";
import { useAppUserStore } from "@/stores/app-user";

const { VITE_API_ID } = import.meta.env;

const client = axios.create({
  baseURL: `https://${VITE_API_ID}.execute-api.us-east-1.amazonaws.com`,
});

client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const appUserStore = useAppUserStore();

  const token = appUserStore.authToken;

  if (!token) {
    const controller = new AbortController();

    const cfg = {
      ...config,
      signal: controller.signal,
    };

    controller.abort("Cannot perform request without access token.");

    return cfg;
  }

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status === 403 &&
      error.response?.data?.message === "Forbidden"
    ) {
      console.warn("invalid token error", error.response.data);

      const appUserStore = useAppUserStore();

      appUserStore.resetUserData();

      window.alert("Your session has expired. Please log in again.");
      window.location.href = "/account/auth";
    }

    return Promise.reject(error);
  },
);

export const callProtectedApi = async <Body, Response>(
  path: string,
  body: Body,
): Promise<AxiosResponse<Response>> => {
  return await client.post<Response>(path, body);
};

export interface GetIdentityAPIRequest {}

export interface GetIdentityAPIResponse {
  identityId: string;
  hasVehicle: boolean;
  vehicles: GarageVehicle[];
  hasPaymentMethod: boolean;
}

export const getIdentity = async () => {
  const response = await callProtectedApi<
    GetIdentityAPIRequest,
    PMC.Common.ApiResponse<GetIdentityAPIResponse>
  >("/v1/getIdentity", {});

  return response.data.data;
};

export interface SaveVehicleAPIRequest {
  tag: string;
  state: string;
  year?: string;
  make?: string;
  model?: string;
  color?: string;
  deleteAt?: boolean | string;
}

export const saveVehicle = async (vehicle: SaveVehicleAPIRequest) => {
  const response = await callProtectedApi<
    SaveVehicleAPIRequest,
    PMC.Common.ApiResponse<unknown>
  >("/v1/saveVehicle", vehicle);

  return response.data.data;
};

export interface RemoveVehicleAPIRequest {
  tag: string;
  state: string;
}

export const removeVehicle = async (vehicle: RemoveVehicleAPIRequest) => {
  await callProtectedApi<
    RemoveVehicleAPIRequest,
    PMC.Common.ApiResponse<unknown>
  >("/v1/removeVehicle", vehicle);

  return;
};

export interface GetSavedVehiclesAPIRequest {}

export const getSavedVehicles = async () => {
  const response = await callProtectedApi<
    GetSavedVehiclesAPIRequest,
    PMC.Common.ApiResponse<unknown>
  >("/v1/getSavedVehicles", {});

  return response.data.data;
};

export interface SavePaymentMethodAPIRequest {
  paymentMethodId: string;
  isDefault?: boolean;
}

export const savePaymentMethod = async (
  paymentMethodId: string,
  isDefault = false,
) => {
  const response = await callProtectedApi<
    SavePaymentMethodAPIRequest,
    PMC.Common.ApiResponse<PaymentMethod>
  >("/v1/savePaymentMethod", { paymentMethodId, isDefault });

  return response.data.data;
};

export interface GetSavedPaymentMethodsAPIRequest {}

export interface GetSavedPaymentMethodAPIRequest {
  paymentMethodId: string;
}

export const getSavedPaymentMethods = async () => {
  const response = await callProtectedApi<
    GetSavedPaymentMethodsAPIRequest,
    PMC.Common.ApiResponse<PaymentMethod[]>
  >("/v1/getSavedPaymentMethods", {});

  return response.data.data;
};

export const getSavedPaymentMethod = async (paymentMethodId: string) => {
  const response = await callProtectedApi<
    GetSavedPaymentMethodAPIRequest,
    PMC.Common.ApiResponse<unknown>
  >("/v1/getSavedPaymentMethods", {
    paymentMethodId,
  });

  return response.data.data;
};

export interface RemoveSavedPaymentMethodAPIRequest {
  paymentMethodId: string;
}

export const removeSavedPaymentMethod = async (paymentMethodId: string) => {
  await callProtectedApi<
    RemoveSavedPaymentMethodAPIRequest,
    PMC.Common.ApiResponse<unknown>
  >("/v1/removeSavedPaymentMethod", { paymentMethodId });

  return;
};

export interface VendGateAPIRequest {
  configId: string;
  locationId: string;
}

export interface VendGateAPIResponse {
  sessionId: string;
}

export const vendGate = async (configId: string, locationId: string) => {
  const response = await callProtectedApi<
    VendGateAPIRequest,
    PMC.Common.ApiResponse<VendGateAPIResponse>
  >("/v1/vendGate", { configId, locationId });

  return response.data.data;
};

export interface GetAccessPointEntryCodeAPIRequest {
  configId: string;
  locationId: string;
}

export interface GetAccessPointEntryCodeAPIResponse {
  code: string;
}

export const getAccessPointEntryCode = async (
  configId: string,
  locationId: string,
) => {
  const response = await callProtectedApi<
    GetAccessPointEntryCodeAPIRequest,
    PMC.Common.ApiResponse<GetAccessPointEntryCodeAPIResponse>
  >("/v1/getAccessPointEntryCode", { configId, locationId });

  return response.data.data;
};

export interface GetActiveSessionViaAPIRequest {
  locationId: string;
  createdVia: "GATE" | "LPR";
}

export const getActiveSessionVia = async (
  locationId: string,
  createdVia: "GATE" | "LPR",
) => {
  const response = await callProtectedApi<
    GetActiveSessionViaAPIRequest,
    PMC.Common.ApiResponse<PMC.Parking.Session>
  >("/v1/getActiveSessionVia", { locationId, createdVia });

  return response.data.data;
};

export interface GetParkingSessionAPIRequest {
  sessionId: string;
}

export interface GetParkingSessionAPIResponse extends PMC.Parking.Session {
  customerId: string;
  createdVia: string;
  version: string;
}

export const getParkingSession = async (sessionId: string) => {
  const response = await callProtectedApi<
    GetParkingSessionAPIRequest,
    PMC.Common.ApiResponse<GetParkingSessionAPIResponse>
  >("/v1/getParkingSession", { sessionId });

  return response.data.data;
};

export interface GetActiveSessionsRequest {}

export const getActiveSessions = async () => {
  const response = await callProtectedApi<
    GetActiveSessionsRequest,
    PMC.Common.ApiResponse<PMC.Parking.Session[]>
  >("/v1/getActiveSessions", {});

  return response.data.data;
};

export interface GetParkingLocationAPIRequest {
  locationId: string;
  operationType?: PMC.Parking.OperationType;
}

export interface GetParkingLocationAPIResponse {
  locationId: string;
  name: string;
  address: PMC.Common.Address;
  timezone: string;
  supportsSaveParkingSpot: boolean;
  operation: PMC.Parking.Operation | null;
}

export const getParkingLocation = async (
  locationId: string,
  operationType?: PMC.Parking.OperationType,
) => {
  const response = await callProtectedApi<
    GetParkingLocationAPIRequest,
    PMC.Common.ApiResponse<GetParkingLocationAPIResponse>
  >("/v1/getParkingLocation", { locationId, operationType });

  return response.data.data;
};

export interface GetParkingHistoryAPIRequest {}

export interface GetParkingHistoryAPIResponse {
  sessions: PMC.Parking.Session[];
}

export const getParkingHistory = async () => {
  const response = await callProtectedApi<
    GetParkingHistoryAPIRequest,
    PMC.Common.ApiResponse<GetParkingHistoryAPIResponse>
  >("/v1/getParkingHistory", {});

  return response.data.data;
};

export interface ConfirmParkingSetupAPIRequest {
  sessionId: string;
  vehicleTag: string;
  vehicleState: string;
  paymentMethodId: string;
  parkingSpot?: string;
}

export interface ConfirmParkingSetupAPIResponse {
  sessionId: string;
}

export const confirmParkingSetup = async (
  payload: ConfirmParkingSetupAPIRequest,
) => {
  const response = await callProtectedApi<
    ConfirmParkingSetupAPIRequest,
    PMC.Common.ApiResponse<ConfirmParkingSetupAPIResponse>
  >("/v1/confirmParkingSetup", payload);

  return response.data.data;
};

export interface ClaimParkingSessionAPIRequest {
  locationId: string;
  vehicleTag?: string;
  vehicleState?: string;
}

export interface ClaimParkingSessionAPIResponse {
  sessionId: string;
}

export const claimParkingSession = async (
  payload: ClaimParkingSessionAPIRequest,
) => {
  const response = await callProtectedApi<
    ClaimParkingSessionAPIRequest,
    PMC.Common.ApiResponse<ClaimParkingSessionAPIResponse>
  >("/v1/claimParkingSession", payload);

  return response.data.data;
};

export interface CreateParkingSessionAPIRequest {
  locationId: string;
  customerName: string;
  customerPhone: string;
  vehicleTag: string;
  vehicleState: string;
  vehicleModel: string;
  paymentMethod: Partial<PaymentMethod>;
  rateId?: string;
  duration?: number;
  departureDate?: string;
  source?: string;
  tosAccepted: boolean;
}

export const createParkingSession = async (
  payload: CreateParkingSessionAPIRequest,
) => {
  const response = await callProtectedApi<
    CreateParkingSessionAPIRequest,
    PMC.Common.ApiResponse<PMC.Parking.Session>
  >("/v1/createParkingSession", payload);

  return response.data.data;
};

export const updateParkingSession = async (
  sessionId: string,
  payload: Partial<PMC.Parking.Session>,
) => {
  const response = await callProtectedApi<
    Partial<PMC.Parking.Session>,
    PMC.Common.ApiResponse<PMC.Parking.Session>
  >("/v1/updateParkingSession", { sessionId, ...payload });

  return response.data.data;
};

export interface CalculateParkingSessionTotalsAPIRequest {
  sessionId: string;
}

export interface CalculateParkingSessionTotalsAPIResponse
  extends PMC.Parking.Session {
  customerId: string;
  createdVia: string;
  version: string;
}

export const calculateParkingSessionTotals = async (sessionId: string) => {
  const response = await callProtectedApi<
    CalculateParkingSessionTotalsAPIRequest,
    PMC.Common.ApiResponse<CalculateParkingSessionTotalsAPIResponse>
  >("/v1/calculateParkingSessionTotals", { sessionId });

  return response.data.data;
};

export interface GetParkingOperationRatesAPIRequest {
  locationId: string;
  type: PMC.Parking.OperationType;
}

export const getParkingOperationRates = async (
  locationId: string,
  type: PMC.Parking.OperationType = "self",
) => {
  const response = await callProtectedApi<
    GetParkingOperationRatesAPIRequest,
    PMC.Common.ApiResponse<PMC.Parking.Rate>
  >("/v1/getParkingOperationRates", { locationId, type });

  return response.data.data;
};

interface UpdateUserAPIRequest {
  name: string;
}

export const updateUser = async (name: string) => {
  const response = await callProtectedApi<
    UpdateUserAPIRequest,
    PMC.Common.ApiResponse<IAMServiceUser>
  >("/v1/updateUser", { name });
  return response.data.data;
};

export const getParkingSessionExitCode = async (sessionId: string) => {
  const response = await callProtectedApi<
    { sessionId: string },
    PMC.Common.ApiResponse<{ code: string }>
  >("/v1/getParkingSessionExitCode", { sessionId });

  return response.data.data;
};
